export default defineNuxtRouteMiddleware(to => {
    if (process.client)
    {
        const tenantStore = useTenantStore();

        if(tenantStore.getAuth)
        {
            return navigateTo('/dashboard/controls');
        }
    }
})